
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './Pages/Home/Home';
// import About from './Pages/About/About';
// import Infrastructure from './Pages/Infrastructure/Infrastructure'
// import Career from './Pages/Career/Career'
// import Products from './Pages/Products/Products'
// import Contact from './Pages/ContactUs/Contact'

function App() {
  return (
    <div className="App">    
      <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Home />} />
         <Route path="/infrastructure" element={<Home />} />
          <Route path="/career" element={<Home />} />
          <Route path="/products" element={<Home />} />
          <Route path="/contact" element={<Home />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
    </div>
  );
}

export default App;
